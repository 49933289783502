import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "./components/layout/Layout";
import HomePage from "./pages/HomePage";

const App = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Navigate to="home" replace={true} />} />
        <Route path="/home" element={<HomePage />} index />
        {/* <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact" element={<ForgotPasswordPage />} /> */}
      </Route>
    </Routes>
  );
};

export default App;
