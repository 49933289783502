import { NavLink } from "react-router-dom";

const navigation = [
  { name: "Home", href: "/home" },
  { name: "Services", href: "/" },
  { name: "About", href: "/" },
  { name: "Experience", href: "/" },
];

const Header = () => {
  return (
    <header className="bg-black fixed top-0 w-full z-50">
      <nav className="mx-auto max-w-7xl px-6 lg:px-8" aria-label="Top">
        <div className="flex w-full items-center justify-between border-b border-gray-500 py-6 lg:border-none">
          <div className="flex items-center">
            <a
              href="/"
              className="text-white font-bold text-xl capitalize border-2 border-transparent rounded-md p-1 px-2 hover:bg-white hover:text-black"
            >
              <span className="sr-only">Leo Santos Portfolio</span>
              LeoSantos.Dev
            </a>
            <div className="ml-10 hidden space-x-8 lg:block">
              {navigation.map((link) => (
                <NavLink
                  key={link.name}
                  to={link.href}
                  className={({ isActive }) =>
                    isActive
                      ? "text-base font-medium text-white py-2 px-4 rounded-md border border-gray-600 bg-gray-600"
                      : "text-base font-medium text-white py-2 px-4 rounded-md border border-transparent hover:border-white"
                  }
                >
                  {link.name}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="ml-10 space-x-4">
            <a
              href="/"
              className="inline-block rounded-md border border-transparent bg-black py-2 px-4 text-base font-medium text-white hover:bg-opacity-75 hover:border-white"
            >
              Contact
            </a>
            <a
              href="/"
              className="inline-block rounded-md border border-transparent bg-white py-2 px-4 text-base font-medium text-black hover:bg-black hover:text-white hover:border-white"
            >
              Hire Me
            </a>
          </div>
        </div>
        <div className="flex flex-wrap justify-center gap-x-6 py-4 lg:hidden">
          {navigation.map((link) => (
            <NavLink
              key={link.name}
              to={link.href}
              className={({ isActive }) =>
                isActive
                  ? "text-base font-medium text-white py-1 px-2 rounded-md border border-white"
                  : "text-base font-medium text-white py-1 px-2 rounded-md border border-transparent hover:border-white"
              }
            >
              {link.name}
            </NavLink>
          ))}
        </div>
      </nav>
    </header>
  );
};

export default Header;
