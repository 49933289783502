const HeroSection = () => {
  return (
    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pb-24 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:mt-6 sm:text-6xl">
              Team Lead Full Stack Developer
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              I'm Leo, a full stack developer and aspiring entrepreneur based in
              Utah. I'm the founder of{" "}
              <a
                href="https://safelyread.com"
                rel="noreferrer"
                target="_blank"
                className="text-purple-700 hover:underline"
              >
                SafelyRead.com
              </a>
              , your go to place to share your confidential or sensitive content
              on the web. I also worked in many projects ranging from simple
              mobile apps to complex SAAS applications.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href="/"
                className="rounded-md bg-black px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                Let's Talk
              </a>
              <a
                href="/"
                className="text-base font-semibold leading-7 text-gray-900"
              >
                Learn more about me <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            src="https://images.unsplash.com/photo-1484807352052-23338990c6c6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            alt="Man typing in a MackBook pro"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
